<div class="main-content">
    <h1 class="page-title m-b-54">Quick Insights (Pro-Bite)</h1>
  
    <div class="row">
      <div class="col-md-12 d-flex justify-content-space">
        <button mat-raised-button class="blackbutton m-b-40" (click)="onclick()"><mat-icon>create</mat-icon>Create Quick Insights (Pro-Bite)</button>
        <!-- <button mat-raised-button class="blackbutton m-b-40" (click)="openNewWindow()">Public View</button> -->
      </div>

    </div>
  
    <!--Table Starts here-->
    <div class="row">
      <section class="example-container mat-elevation-z8" id="scrollbar">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef class="table-headings">
                  <!-- Add a checkbox for select all -->
                  <mat-checkbox (change)="toggleAllCheckboxes($event)"></mat-checkbox> S#
                </th>
                <td mat-cell *matCellDef="let element; let i = index"> 
                  <!-- Add a checkbox for individual rows -->
                  <mat-checkbox (change)="toggleCheckbox(element.id)" [checked]="selectedIds.includes(element.id)"></mat-checkbox>
                  {{i + 1 + paginator.pageIndex * paginator.pageSize}} 
                </td>
            </ng-container>

          <ng-container matColumnDef="QuickInsights">
            <th mat-header-cell *matHeaderCellDef class="table-headings">Quick Insights(pro-bite)</th>
            <td mat-cell *matCellDef="let element">{{element.QuickInsightsName}} </td>
          </ng-container>
  
          <ng-container matColumnDef="Created">
            <th mat-header-cell *matHeaderCellDef class="table-headings">Created</th>
            <td mat-cell *matCellDef="let element">{{element.CreatedOn | dateFormat }}</td>
          </ng-container>

          <ng-container matColumnDef="Updatedon">
            <th mat-header-cell *matHeaderCellDef class="table-headings">Updated on</th>
            <td mat-cell *matCellDef="let element">{{element.UpdatedOn | dateFormat}}</td>
          </ng-container>
  
          <ng-container matColumnDef="totalelectors">
            <th mat-header-cell *matHeaderCellDef class="table-headings">Access Type</th>
            <td mat-cell *matCellDef="let element">{{element.Status_Tag}}</td>
          </ng-container>

          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef class="table-headings">Action</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="refreshPage()"> 
                  <mat-icon>refresh</mat-icon>
                  <span>Refresh</span>
                </button>
                <button mat-menu-item (click)="editview(element)">
                  <mat-icon>edit</mat-icon>
                  <span>Edit & View</span>
                </button>
                <button mat-menu-item (click)="CopyEdit(element)">
                  <mat-icon>file_copy</mat-icon>
                  <span>Copy and Edit</span>
                </button>
                <button mat-menu-item>
                  <mat-icon>share</mat-icon>
                  <span>Share</span>
                </button>
                <button mat-menu-item (click)="Visualize(element)">
                  <mat-icon>query_stats</mat-icon>
                  <span>Visualize</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-row"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>
        <!-- Message to display when there is no data -->
        <div *ngIf="dataSource.data.length === 0" class="text-center">
          <p class="f-s-18 m-t-54">
            To add, Click on
            <a class="communicationlink textdecoration-underline pointercursor" (click)="onclick()">Create Quick Insights</a>
          </p>
        </div>
        <div class="sticky-paginator">
          <mat-paginator [pageSizeOptions]="[50, 100]" showFirstLastButtons></mat-paginator>
        </div>
      </section>
    </div>
  </div>

  <div class="row d-flex m-t-64 justify-content-center m-l-48">
    <div class="col-md-6 d-flex">
      <mat-label class="mat-subtitle-2 f-w-600 d-block">File Type</mat-label>
      <mat-form-field appearance="outline">
          <mat-select required placeholder="File" [(ngModel)]="selectedFileType" #fileSelect="ngModel">
            <mat-option value="pdf">PDF</mat-option>
            <mat-option value="csv">Excel</mat-option> 
          </mat-select>
      </mat-form-field>
    
      <div class="col-md-8 m-t-2">
        <button mat-raised-button  class="save-cancel-button rounded-pill exportselection m-t-10"  (click)="download()" (click)="exportSelection()">
          Export Selection
        </button>
        <p class="error-message" *ngIf="fileSelect.invalid && (fileSelect.touched || fileSelect.control.dirty)">{{ errorMessage }}</p>
    </div>
    </div>
  </div>
<div class="container" >
    <div class="row d-flex justify-content-between">
        <div class="col-md-6">
            <!-- <h2>Pie Chart</h2> -->
            <div class="card m-t-20" [ngClass]="{ 'disabled-card': isLoading }">
            <form class="m-t-40 m-l-32">
                <h4>Filter</h4>
                <mat-form-field appearance="fill">
                <mat-label>Filter By State</mat-label>
                <mat-select name="states" (selectionChange)="dropdownnames($event)" [value]="defaultOption">
                    <mat-option value="KRISHNAMPALEM-2">KRISHNAMPALEM-2</mat-option>
                    <mat-option value="JAITHAVARAM-98">JAITHAVARAM-98</mat-option>
                    <mat-option value="TURUVOLU-103">TURUVOLU-103</mat-option>
                    <mat-option value="G.KOTHAPALLI-107">G.KOTHAPALLI-107</mat-option>
                    <mat-option value="CHUKKAPALLI-114">CHUKKAPALLI-114</mat-option>
                </mat-select>
                </mat-form-field>
            </form>
            <div *ngIf="isLoading" class="loader">
                <mat-spinner diameter="100"></mat-spinner>
            </div>
            <div #pieChart class="piechart"></div>
            </div>
        </div>    

        <div class="col-md-6">
            <app-map></app-map>
        </div>

        <div class="col-md-6">
            <app-bar></app-bar>
        </div>
        
    </div> 

</div>
  
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { IvinService } from 'src/app/ivin.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() index: number = 1;
  // @Input() totalCount: number = 50;
  @Input() pageSize: number = 5;
  @Input() rulerLength: number = 5;

  @Output() page: EventEmitter<number> = new EventEmitter<number>();
  usertypeid: any;
  data: any;
  ids: any[] = [];
  totalCount: any;

  constructor(private ivinservice: IvinService, private router: Router) {}

  ngOnInit() {
    this.usertypeid = sessionStorage.getItem("loginid");
    this.Gettabledata();

    // Retrieve and set the current page index from sessionStorage
    const savedIndex = sessionStorage.getItem('currentPageIndex');
    if (savedIndex) {
      this.index = +savedIndex;
    }

    // Ensure default page id logs when the data is ready
    this.page.subscribe((pageIndex) => {
      if (pageIndex === 1 && this.ids && this.ids.length > 0) {
        console.log('Initial page id:', this.ids[0]); // Logs the first id by default
      }
    });
  }

  get maxPages(): number {
    console.log('Total ids Count:', this.totalCount);
    return Math.ceil(this.totalCount);
  }
 

  get pagination(): { index: number; maxPages: number; pages: number[] } {
    const { index, maxPages, rulerLength } = this;
    const pages = this.ruler(index, maxPages, rulerLength);
    return { index, maxPages, pages };
  }



  

navigateToPage(pageNumber: number): void {
  if (this.allowNavigation(pageNumber, this.index, this.maxPages)) {
    this.index = pageNumber;
    this.page.emit(this.index);
    console.log('maxPages:', this.maxPages);

    // Calculate the id of the current page
    // const idIndex = (pageNumber - 1)  % this.ids.length;
    const idIndex = (pageNumber - 1)

    if (this.ids[idIndex]) {
      const currentId = this.ids[idIndex];
      
      // Log the page number and corresponding ID
      console.log(`Page Number: ${pageNumber}, Current Page ID: ${currentId}`);

      // Store the current page ID and index in session storage
      sessionStorage.setItem('currentId', currentId);
      sessionStorage.setItem('currentPageIndex', pageNumber.toString());
    } else {
      console.error('No data for page:', pageNumber);
    }

    // Navigate to the PublicCharts component
    this.navigateToPublicCharts();
  }
}

  
  navigateToPublicCharts(): void {
    if (this.router.url === '/publiccharts') {
      // If already on the PublicCharts route, force a reload
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/publiccharts']);
      });
    } else {
      
      this.router.navigate(['/publiccharts']);
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  private ruler(currentIndex: number, maxPages: number, rulerLength: number): number[] {
    const array = new Array(rulerLength).fill(null);
    const min = Math.floor(rulerLength / 2);

    return array.map((_, index) =>
      this.rulerFactory(currentIndex, index, min, maxPages, rulerLength)
    );
  }

  private rulerOption(currentIndex: number, min: number, maxPages: number): 'Start' | 'End' | 'Default' {
    return currentIndex <= min
      ? 'Start'
      : currentIndex >= maxPages - min
      ? 'End'
      : 'Default';
  }

  private rulerFactory(
    currentIndex: number,
    index: number,
    min: number,
    maxPages: number,
    rulerLength: number
  ): number {
    const factory = {
      Start: () => index + 1,
      End: () => maxPages - rulerLength + index + 1,
      Default: () => currentIndex + index - min,
    };

    return factory[this.rulerOption(currentIndex, min, maxPages)]();
  }

  private allowNavigation(pageNumber: number, index: number, maxPages: number): boolean {
    const allowed = pageNumber !== index && pageNumber > 0 && pageNumber <= maxPages;
    console.log(`Allow navigation to page ${pageNumber}: ${allowed}`);
    return allowed;
  }




  Gettabledata() {
    const data = {
      UserId :  this.usertypeid,
      Status_Tag : 'Public',
    }
    this.ivinservice.probitegetall(data).subscribe(
      (data: any) => {
        if (data && data.Result) {
          this.data = data.Result.sort((a: any, b: any) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime());
          this.ids = this.data.map((item: any) => item.id);
          this.totalCount = this.ids.length;
          console.log('Total Countss:', this.totalCount);
          console.log("Pagination ids", this.ids);
          if (this.ids.length > 0) {
            const initialId = this.ids[0];
            console.log('Initial page id:', initialId);
            sessionStorage.setItem('currentId', initialId);
          }
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }
}
<!--Designed and Developed by Krishna Kanth Hanumanthu & Syamala Kolusu-->
<div class="blank-layout-container align-items-center justify-content-center overflowhidden">
  <div>
      <img class="w-5" src="assets/images/logos/ivin-logo.svg" alt="Logo">
  </div>
  <div class="position-relative row w-100 h-100">
    <!--Image div starts here-->
    <div class="col-lg-6 col-xl-8 p-0 bg-gredient bg-light-primary">
        <div>
            <div class="p-24 h-100 align-items-center justify-content-center img-height d-none d-lg-flex">
                <div *ngFor="let tile of tiles;">
                    <img class="w-100" src="{{tile.imageUrl}}">
                </div>
            </div>
        </div>
    </div>
    <!--Image div ends here-->

    <!--Form div starts here-->
    <div class="col-lg-6 col-xl-4 p-0 d-flex justify-content-center">
        <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100 max-width-form">
            <div class="row justify-content-center w-100">
                <div class="col-lg-12 boxshadow-form m-l-64 m-r-64 p-t-20 p-b-20">
                  <div class="text-center align-items-center m-t-10">
                    <div class="f-w-500 d-grid">
                    <mat-card-title class="f-s-24 m-b-10">
                        Welcome to Idovin Strategies!
                    </mat-card-title>
                    <mat-card-subtitle class="m-b-20 f-s-12">
                        Unlocking Insights with Data-Driven Strategies using Pro and Pro-Bite.
                    </mat-card-subtitle>
                    <div>
                      <h2>Forgot Password</h2>
                      <div class="title-border"></div>
                    </div>
                    
                    </div>
                  </div>
                  <form class="m-t-20 p-r-20 p-l-20" (ngSubmit)="forgetpasswordpost()">
                    <mat-label class="d-block f-w-500">Email</mat-label>
                    <mat-form-field appearance="outline" class="w-100" color="primary">
                      <span class="material-symbols-outlined p-r-4">
                        person
                    </span>
                      <input matInput type="text" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
                      <mat-error *ngIf="emailFormControl.hasError('required')">Email <strong>required</strong></mat-error>
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="w-100 f-s-16 f-w-500 login-button">Reset Password</button>
                  </form>
                  <div class="m-t-20 m-b-10">
                    <span class="f-s-16 f-w-400 justify-content-center d-flex align-items-center">Remember your password?
                        <a class="m-l-4 f-w-700 text-primary pointercursor" (click)="login()">Login</a>
                    </span>
                  </div>
                </div>
            </div>
        </div>
      </div>
    <!--Form div ends here-->
</div>
</div>
<footer>
Copyright © 2024 | Idovin Strategies Pvt. Ltd. or its affiliates. All rights reserved. Discover ivin Pro & ivin Pro-bite
</footer>
<mat-accordion class="accordion-container">
  <mat-expansion-panel
    class="custom-expansion-panel"
    *ngFor="let social of socialdata"
    [ngSwitch]="social.SocialMediaType"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon *ngSwitchCase="'Telegram'">telegram</mat-icon>
        <mat-icon *ngSwitchCase="'Twitter'">twitter</mat-icon>
        <mat-icon *ngSwitchCase="'LinkedIn'">linkedin</mat-icon>
        <mat-icon *ngSwitchCase="'Instagram'">instagram</mat-icon>
        <mat-icon *ngSwitchCase="'Facebook'">facebook</mat-icon>
        <mat-icon *ngSwitchCase="'YouTube'">youtube</mat-icon>
        <mat-icon *ngSwitchCase="'WhatsApp'">whatsapp</mat-icon>
        {{ social.SocialMediaType }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="social.SocialMediaLink">
      <a [href]="social.SocialMediaLink" target="_blank">
        {{ social.SocialMediaLink }}
      </a>
    </div>
  </mat-expansion-panel>
</mat-accordion>

  
   <!--Button-->
   <div class="row justify-content-end m-b-64 m-t-32">
      <div class="col-sm-8">
          <button mat-raised-button class="m-r-12 m-b-12 blackbutton save-cancel-button" (click)="outcorrections(socialmediadialogbox)">Add or Update</button>
      </div>
    </div>

<ng-container>
    <ng-template #socialmediadialogbox>
        <div mat-dialog-title class="text-center popupheader-black">Social Media</div>
            <form (ngSubmit)="socialmediapost()"> 
                <mat-dialog-content id="scrollbar">
                    <div class="row m-t-20 m-r-20">
                        <div class="col-lg-12">
                            <div class="row">
                                <div (click)="openupdatename(each)" *ngFor="let each of socialdata; let i = index">
                                    <div class="col-md-12 d-flex m-b-24"> 
                                        <div class="col-sm-12 m-r-32 borderradius">
                                            <table>
                                                <tr>
                                                    <ng-container>
                                                        <td>
                                                            <span class="f-w-600">Social Media Type &nbsp;</span>
                                                        </td>
                                                        <td>
                                                            : {{each.SocialMediaType || 'N/A'}}
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                                <tr>
                                                    <td><span class="f-w-600">link &nbsp;</span></td>
                                                    <td>: {{each.SocialMediaLink || 'N/A'}}</td>
                                                </tr>
                                                <tr>
                                                    <td><span class="f-w-600">Description &nbsp;</span></td>
                                                    <td>: {{each.SocialMediaDescription || 'N/A'}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <button type="button" class="card-edit-button" (click)="removesocial(each)"><span class="material-symbols-outlined" style="color: black; font-size: 18px;">close</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--this is for multiple post-->
                    <ng-template [ngIf]="displayName==='post'">
                        <div class="row d-flex m-d-8">
                            <div class="col-sm-3 d-flex align-items-center">
                                <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Add Accounts</mat-label> -->
                            </div>
                    
                            <div class="col-md-8 Socialmedia">
                                <mat-form-field appearance="outline" class="w-100 m-t-16">
                                    <mat-select required [formControl]="officeheld" placeholder="Select Social Media Type">
                                        <mat-option>-- None --</mat-option>
                                        <mat-option value="Telegram">Telegram</mat-option>
                                        <mat-option value="Twitter">Twitter</mat-option>
                                        <mat-option value="Linkedin">Linkedin</mat-option>
                                        <mat-option value="Instagram">Instagram</mat-option>
                                        <mat-option value="Facebook">Facebook</mat-option>
                                        <mat-option value="Whatsapp">Whatsapp</mat-option>
                                        <mat-option value="Youtube">YouTube</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100 place">
                                    <input matInput [formControl]="urllink"  placeholder="Add Your Link" required>
                                </mat-form-field>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mat-form-field appearance="outline" class="w-100 custom-placeholder">
                                                <textarea matInput id="Description" [formControl]="Description" placeholder="Type Your Description"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                            </div>
                                <!-- <div class="d-flex align-items-center m-l-8">
                                    <mat-icon (click)="addCard()">add</mat-icon>
                                </div> -->
                        </div>
                    </ng-template>

                        <!-- this is for multiple update -->
                    <ng-template [ngIf]="displayName==='update'">
                        <div class="row d-flex m-d-8">
                            <div class="col-sm-3 d-flex align-items-center">
                                <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Links</mat-label> -->
                            </div>
                    
                            <div class="col-md-8 Socialmedia">
                                <mat-form-field appearance="outline" class="w-100 m-t-16 place">
                                    <mat-select required [formControl]="officeheldupdate" placeholder="Select Social Media Type">
                                        <mat-option>-- None --</mat-option>
                                        <mat-option value="Telegram">Telegram</mat-option>
                                        <mat-option value="Twitter">Twitter</mat-option>
                                        <mat-option value="Linkedin">Linkedin</mat-option>
                                        <mat-option value="Instagram">Instagram</mat-option>
                                        <mat-option value="Facebook">Facebook</mat-option>
                                        <mat-option value="Whatsapp">Whatsapp</mat-option>
                                        <mat-option value="Youtube">YouTube</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100 place">
                                    <input matInput [formControl]="urllinkupdate"  placeholder="Add Your Link" required>
                                </mat-form-field>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mat-form-field appearance="outline" class="w-100 custom-placeholder ">
                                                <textarea matInput id="Description" [formControl]="updateDescription" placeholder="Type Description"></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                            </div>
                                <!-- <div class="d-flex align-items-center m-l-8">
                                    <mat-icon (click)="addCardupdate()">add</mat-icon>
                                </div> -->
                        </div> 
                      </ng-template>
                </mat-dialog-content>
                <!--Button-->
                <mat-dialog-actions align="center">
                    <button mat-raised-button class="blackbutton">Save</button>
                    <button mat-button mat-dialog-close class="m-r-20 cancel-border">Cancel</button>
                </mat-dialog-actions>
            </form>
    </ng-template>
</ng-container>


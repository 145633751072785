import { Component } from '@angular/core';

@Component({
  selector: 'app-releasestats',
  templateUrl: './releasestats.component.html',
  styleUrls: ['./releasestats.component.scss']
})
export class ReleasestatsComponent {

}

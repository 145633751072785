import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as d3 from 'd3';
import { GeoPath, GeoProjection } from 'd3-geo';
import { FeatureCollection, Geometry } from 'geojson';

// Declare google variable
declare const google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  @ViewChild('mapContainer', { static: true }) mapContainer!: ElementRef;
  private svg: any;
  private tooltip: any;
  private stateValues: Map<string, number> = new Map();
  private stateNames: string[] = [];

  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.populateStateValues();
    this.createMap();
  }

  ngAfterViewInit(): void {
    // Add any required functionality for after view initialization here
  }

  private populateStateValues(): void {
    // Define the manual data
    const stateData: [string, string][] = [
      ['Jammu and Kashmir', '200'],
      ['Himachal Pradesh', '300'],
      ['Punjab', '400'],
      ['Uttarakhand', '500'],
      ['Haryana', '600'],
      ['NCT of Delhi', '700'],
      ['Uttar Pradesh', '800'],
      ['Rajasthan', '900'],
      ['Gujarat', '400'],
      ['Arunachal Pradesh', '700'],
      ['Karnataka', '300'],
      ['Orissa', '500'],
      ['Tamil Nadu', '400'],
      ['Telangana', '300'],
      ['Bihar', '400'],
      ['West Bengal', '300'],
      ['Kerala', '200'],
      ['Meghalaya', '40'],
      ['Manipur', '60'],
      ['Mizoram', '700'],
      ['Nagaland', '800'],
      ['Tripura', '80'],
      ['Sikkim', '80'],
      ['Goa', '700'],
      ['Chandigarh', '700'],
      ['Madhya Pradesh', '600'],
      ['Maharashtra', '500'],
      ['Andhra Pradesh', '1000'],
      ['Assam', '800'],
      ['Nepal', '700']
    ];

    // Populate stateValues map
    stateData.forEach(([state, value]) => {
      this.stateValues.set(state, Number(value)); // Convert value to number if needed
    });
  }

  private createMap(): void {
    const element = this.mapContainer.nativeElement;
    const width = 300;
    const height = 400;

    // Initialize D3.js SVG
    this.svg = d3.select(element).append('svg')
      .attr('width', width)
      .attr('height', height);

    // Create a tooltip
    this.tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', '1px solid white')
      .style('padding', '3px')
      .style('pointer-events', 'none');

    // Load India GeoJSON data using HttpClient
    this.httpClient.get<FeatureCollection<Geometry, { NAME_1: string }>>('assets/data/geo/India.geojson')
      .subscribe((data: FeatureCollection<Geometry, { NAME_1: string }>) => {
        console.log('GeoJSON data loaded successfully:', data);

        // Store state names
        this.stateNames = data.features.map(feature => feature.properties.NAME_1);
        console.log('statenames',this.stateNames);

        // Create a projection
        const projection: GeoProjection = d3.geoMercator()
          .fitSize([width, height], data);

        const path: GeoPath = d3.geoPath().projection(projection);

        // Render India map with states
        this.svg.selectAll('path')
          .data(data.features)
          .enter().append('path')
          .attr('d', path)
          .style('fill', 'lightblue')
          .style('stroke', 'white')
          .style('stroke-width', '1px')
          .on('mouseover', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
            d3.select(event.currentTarget as HTMLElement).style('fill', 'orange');
            const stateValue = this.stateValues.get(d.properties.NAME_1) || 0;
            this.tooltip.transition()
              .duration(200)
              .style('opacity', .9);
            this.tooltip.html(`State: ${d.properties.NAME_1} popularity: ${stateValue}`)
              .style('left', (event.pageX + 5) + 'px')
              .style('top', (event.pageY - 28) + 'px');
            console.log('Hovered state:', d.properties.NAME_1, 'Value:', stateValue);
          })
          .on('mouseout', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
            d3.select(event.currentTarget as HTMLElement).style('fill', 'lightblue');
            this.tooltip.transition()
              .duration(500)
              .style('opacity', 0);
          })
          .on('mousemove', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
            this.tooltip.style('left', (event.pageX + 5) + 'px')
              .style('top', (event.pageY - 28) + 'px');
          })
          .on('click', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
            console.log('Clicked state:', d.properties.NAME_1);
            d3.select(event.currentTarget as HTMLElement).style('fill', 'yellow');
          });
      });
  }
}



// private populateStateValues(): void {
  //   // Define the manual data
  //   // const stateData: [string, string][] = [
  //   //   ['Jammu and Kashmir', '200'],
  //   //   ['Himachal Pradesh', '300'],
  //   //   ['Punjab', '400'],
  //   //   ['Uttarakhand', '500'],
  //   //   ['Haryana', '600'],
  //   //   ['NCT of Delhi', '700'],
  //   //   ['Uttar Pradesh', '800'],
  //   //   ['Rajasthan', '900'],
  //   //   ['Gujarat', '400'],
  //   //   ['Arunachal Pradesh', '700'],
  //   //   ['Karnataka', '300'],
  //   //   ['Orissa', '500'],
  //   //   ['Tamil Nadu', '400'],
  //   //   ['Telangana', '300'],
  //   //   ['Bihar', '400'],
  //   //   ['West Bengal', '300'],
  //   //   ['Kerala', '200'],
  //   //   ['Meghalaya', '40'],
  //   //   ['Manipur', '60'],
  //   //   ['Mizoram', '700'],
  //   //   ['Nagaland', '800'],
  //   //   ['Tripura', '80'],
  //   //   ['Sikkim', '80'],
  //   //   ['Goa', '700'],
  //   //   ['Chandigarh', '700'],
  //   //   ['Madhya Pradesh', '600'],
  //   //   ['Maharashtra', '500'],
  //   //   ['Andhra Pradesh', '1000'],
  //   //   ['Assam', '800'],
  //   //   ['Nepal', '700']
  //   // ];

  //   // // Populate stateValues map
  //   // stateData.forEach(([state, value]) => {
  //   //   this.stateValues.set(state, Number(value)); // Convert value to number if needed
  //   // });
  // }

  // createMap(): void {
  //   const element = this.mapContainer.nativeElement;
  //   const width = 300;
  //   const height = 400;

  //   // Initialize D3.js SVG
  //   this.svg = d3.select(element).append('svg')
  //     .attr('width', width)
  //     .attr('height', height);

  //   // Create a tooltip
  //   this.tooltip = d3.select('body').append('div')
  //     .attr('class', 'tooltip')
  //     .style('opacity', 0)
  //     .style('position', 'absolute')
  //     .style('background-color', 'white')
  //     .style('border', '1px solid white')
  //     .style('padding', '3px')
  //     .style('pointer-events', 'none');

  //   // Load India GeoJSON data using HttpClient
  //   this.httpClient.get<FeatureCollection<Geometry, { NAME_1: string }>>('assets/data/geo/India.geojson')
  //     .subscribe((data: FeatureCollection<Geometry, { NAME_1: string }>) => {
  //       console.log('GeoJSON data loaded successfully:', data);

  //       // Store state names
  //       this.stateNames = data.features.map(feature => feature.properties.NAME_1);
  //       console.log('statenames',this.stateNames);

  //       // Create a projection
  //       const projection: GeoProjection = d3.geoMercator()
  //         .fitSize([width, height], data);

  //       const path: GeoPath = d3.geoPath().projection(projection);

  //       // Render India map with states
  //       this.svg.selectAll('path')
  //         .data(data.features)
  //         .enter().append('path')
  //         .attr('d', path)
  //         .style('fill', 'lightblue')
  //         .style('stroke', 'white')
  //         .style('stroke-width', '1px')
  //         .on('mouseover', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
  //           d3.select(event.currentTarget as HTMLElement).style('fill', 'orange');
  //           const stateValue = this.stateValues.get(d.properties.NAME_1) || 0;
  //           const stateData = this.bardata.find((item: any) => item.state === d.properties.NAME_1);
  //           if (stateData) {
  //             const tooltipHtml = `
  //             <div style="font-weight: bold;">State: ${d.properties.NAME_1}</div>
  //             <div>Popularity: ${stateValue}</div>
  //             <div style="margin-top: 5px;">Party Wins:</div>
  //             <ul style="margin: 0; padding-left: 20px;">
  //               ${stateData.wins.map((w: any) => `<li>${w.party}: ${w.wins}</li>`).join('')}
  //             </ul>
  //             `;
  //             this.tooltip.transition()
  //               .duration(200)
  //               .style('opacity', .9);
  //             this.tooltip.html(tooltipHtml)
  //               .style('left', (event.pageX + 5) + 'px')
  //               .style('top', (event.pageY - 28) + 'px');
  //             console.log('Hovered state:', d.properties.NAME_1, 'Value:', stateValue);
  //           }
  //         })
  //         .on('mouseout', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
  //           d3.select(event.currentTarget as HTMLElement).style('fill', 'lightblue');
  //           this.tooltip.transition()
  //             .duration(500)
  //             .style('opacity', 0);
  //         })
  //         .on('mousemove', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
  //           this.tooltip.style('left', (event.pageX + 5) + 'px')
  //             .style('top', (event.pageY - 28) + 'px');
  //         })
  //         .on('click', (event: MouseEvent, d: GeoJSON.Feature<Geometry, { NAME_1: string }>) => {
  //           console.log('Clicked state:', d.properties.NAME_1);
  //           d3.select(event.currentTarget as HTMLElement).style('fill', 'yellow');
  //         });
  //     });
  // }
  






  // private googleMapsApiKey = 'AIzaSyB_7WJjb_8_1l3IEJQLrqA6m4gGExEx-c8';
  // defaultOption: any = 'KRISHNAMPALEM-2';
  // pollingstationame: any;
  // votersdata: any;
  // totalvoters: any;
  // malevoters: any;
  // isLoading: boolean = false;
  // femalevoters: any;

  // @ViewChild('mapChart', { static: true }) mapChart!: ElementRef;

  // constructor(private ivinservice: IvinService) {}

  // ngAfterViewInit() {
  //   this.loadGoogleMapsApi().then(() => {
  //     google.charts.load('current', { packages: ['geochart'] });
  //     google.charts.setOnLoadCallback(() => {
  //       this.drawGeoChart();
  //     });
  //   });
  // }

  // public map_data = [
  //   ['State', 'Popularity'],
  //   ['Jammu and Kashmir', 200],
  //   ['Himachal Pradesh', 300],
  //   ['Punjab', 400],
  //   ['Uttarakhand', 500],
  //   ['Haryana', 600],
  //   ['Delhi', 700],
  //   ['Uttar Pradesh', 800],
  //   ['Rajasthan', 900],
  //   ['Gujarat', 400],
  //   ['Arunachal Pradesh', 700],
  //   ['Karnataka', 300],
  //   ['Orissa', 500],
  //   ['Tamil Nadu', 400],
  //   ['Telangana', 300],
  //   ['Bihar', 400],
  //   ['West Bengal', 300],
  //   ['Kerala', 200],
  //   ['Meghalaya', 40],
  //   ['Manipur', 60],
  //   ['Mizoram', 700],
  //   ['Nagaland', 800],
  //   ['Tripura', 80],
  //   ['Sikkim', 80],
  //   ['Goa', 700],
  //   ['Chandigarh', 700],
  //   ['Madhya Pradesh', 600],
  //   ['Maharashtra', 500],
  //   ['Andhra Pradesh', 1000],
  //   ['Assam', 800],
  //   ['Nepal', 700]
  // ];

  // public map_Chartoption = {
  //   region: 'IN',
  //   displayMode: 'region',
  //   resolution: 'provinces',
  //   colorAxis: { colors: ['green'] }
  // };

  // loadGoogleMapsApi(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     if (typeof google === 'object' && typeof google.maps === 'object') {
  //       resolve();
  //       return;
  //     }
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsApiKey}&libraries=visualization&loading=async`;
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = () => resolve();
  //     script.onerror = (error: any) => reject(error);
  //     document.head.appendChild(script);
  //   });
  // }

  // drawGeoChart() {
  //   const chartData = google.visualization.arrayToDataTable(this.map_data);
  //   const chart = new google.visualization.GeoChart(this.mapChart.nativeElement);
  //   chart.draw(chartData, this.map_Chartoption);
  // }
// }

<!-- Bar chart -->
<div class="col-md-6">
    <div class="card m-t-20">
        <form class="m-t-40 m-l-32">
        <h4>Filter</h4>
        <mat-form-field appearance="fill">
            <mat-label>Filter By State</mat-label>
            <mat-select name="states">
            <mat-option value="andhrapradesh">Andhra pradesh</mat-option>
            <mat-option value="kerala">Kerala</mat-option>
            <mat-option value="tamilnadu">Tamilnadu</mat-option>
            </mat-select>
        </mat-form-field>
        </form>
        <div #barChart class="map m-b-48"></div>
    </div>
</div>
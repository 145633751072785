<!--Designed and Developed by Krishna Kanth Hanumanthu & Syamala Kolusu-->
<div class="blank-layout-container align-items-center justify-content-center overflowhidden">
  <div>
      <img class="w-5" src="assets/images/logos/ivin-logo.svg" alt="Logo">
  </div>
  <div class="position-relative row w-100 h-100">
    <!--Image div starts here-->
    <div class="col-lg-6 col-xl-8 p-0 bg-gredient bg-light-primary">
        <div>
            <div class="p-24 h-100 align-items-center justify-content-center img-height d-none d-lg-flex">
                <div *ngFor="let tile of tiles;">
                    <img class="w-100" src="{{tile.imageUrl}}">
                </div>
            </div>
        </div>
    </div>
    <!--Image div ends here-->

    <div class="col-lg-6 col-xl-4 p-0 d-flex justify-content-center">
      <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100 max-width-form">
          <div class="row justify-content-center w-100">
              <div class="col-lg-12 boxshadow-form m-l-64 m-r-64 p-t-20 p-b-20">
                <div class="text-center align-items-center m-t-10">
                  <div class="f-w-500 d-grid">
                  <mat-card-title class="f-s-24 m-b-10">
                      Welcome to Idovin Strategies!
                  </mat-card-title>
                  <mat-card-subtitle class="m-b-20 f-s-12">
                      Unlocking Insights with Data-Driven Strategies using Pro and Pro-Bite.
                  </mat-card-subtitle>
                  </div>
                </div>
                <div class="m-t-12">
                  <p class="text-center f-s-14 m-t-12 text-gray">
                    Enter the 4 digits code that was sent to your Email<br>
                    <span class="text-center f-s-14 blackcolor m-b-10">{{ recieveotpemail }}</span>
                  </p>
                </div>
                <form (ngSubmit)="SubmitOtp()" class="p-r-20 p-l-20">
                  <div class="otp-input m-t-40 d-flex otpboxes">
                    <input #input1 type="text" maxlength="1" [(ngModel)]="otpValue[0]" (keypress)="validateNumericInput($event)"(input)="onOtpInputChange(0, input2, null)" (keydown)="onKeyDown($event, null, input2)" [ngModelOptions]="{ standalone: true }" autofocus [ngClass]="{'error-border': invalidDetailsMessage}" />
                    <input #input2 type="text" maxlength="1" [(ngModel)]="otpValue[1]" (keypress)="validateNumericInput($event)" (input)="onOtpInputChange(1, input3, input1)" (keydown)="onKeyDown($event, input1, input3)" [ngModelOptions]="{ standalone: true }" [ngClass]="{'error-border': invalidDetailsMessage}" />
                    <input #input3 type="text" maxlength="1" [(ngModel)]="otpValue[2]" (keypress)="validateNumericInput($event)"(input)="onOtpInputChange(2, input4, input2)" (keydown)="onKeyDown($event, input2, input4)" [ngModelOptions]="{ standalone: true }" [ngClass]="{'error-border': invalidDetailsMessage}" />
                    <input #input4 type="text" maxlength="1" [(ngModel)]="otpValue[3]" (keypress)="validateNumericInput($event)"(input)="onOtpInputChange(3, null, input3)" (keydown)="onKeyDown($event, input3, null)" [ngModelOptions]="{ standalone: true }" [ngClass]="{'error-border': invalidDetailsMessage}" />
                  </div>

                  <div class="text-center f-s-16 m-t-28 m-b-20">
                      {{ otpSentMessage || (otpExpired ? "Your OTP has expired. Click on Resend to get a new one." : "Didn't receive the OTP?") }}
                      <span [ngStyle]="{'color': resendButtonDisabled ? '#999999' : '#8F1402', 'cursor': resendButtonDisabled ? 'not-allowed' : 'pointer'}" (click)="ResendOtp()" [attr.disabled]="resendButtonDisabled ? 'disabled' : null" class="f-w-700 text-primary focus:outline-none">
                        {{ countdownSeconds > 0 ? formatCountdownTime(countdownSeconds) : 'Resend' }}
                      </span>
                  </div>

                  <button mat-raised-button color="primary" [disabled]="!isOtpValid"class="w-100 f-s-16 f-w-500 login-button">Verify</button>
                </form>
                <div class="m-t-20 m-b-10">
                  <span class="f-s-16 f-w-400 justify-content-center d-flex align-items-center">Remember your password?
                      <a class="m-l-4 f-w-700 text-primary pointercursor" (click)="login()">Login</a>
                  </span>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
<footer>
Copyright © 2024 | Idovin Strategies Pvt. Ltd. or its affiliates. All rights reserved. Discover ivin Pro & ivin Pro-bite
</footer>


  
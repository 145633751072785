<div class="row d-flex justify-content-space charts-bg">
  
    <div class="col-md-6">
      <div class="card">
        <div class="row justify-content-center">
          <span class="tabletitle m-t-20 f-s-24">Election Summary</span>
        </div>
        <div class="row justify-content-center">
          <span class="m-t-6">
            {{state}}
            <ng-container *ngIf="state && election"> &#8594; </ng-container>
            {{election}}
            <ng-container *ngIf="election && year"> &#8594; </ng-container>
            {{year}}
            <ng-container *ngIf="year && constituency"> &#8594; </ng-container>
            {{constituency}}
          </span>
        </div>
  
        <div class="row justify-content-center m-t-54" *ngIf="!tabledata || tabledata.length === 0">
          <p>No data available.</p>
        </div>
  
        <div id="summarychart" style="width: 100%; height: 200px;"></div>
        
        <table class="m-b-10 m-l-32" *ngIf="tabledata && tabledata.length > 0">
          <tr>
            <td>Electors:</td>
            <td>{{ tabledata[0].SumTotalElectors | number }}</td>
          </tr>
          <tr>
            <td>Votes Polled:</td>
            <td>{{ tabledata[0].SumTotalPolled | number }}</td>
          </tr>
          <tr>
            <td>Turnout:</td>
            <td>{{ tabledata[0].PercentagePolled | number:'1.2-2' }}%</td>
          </tr>
          <tr>
            <td>Total Constituencies:</td>
            <td>{{ tabledata[0].ACCount }}</td>
          </tr>
          <tr>
            <td colspan="2">
              <span *ngFor="let result of tabledata[0].ACCategoryResult; let i = index">
                {{ result.caste }} : {{ result.count }}<span *ngIf="i < tabledata[0].ACCategoryResult.length - 1"> , </span>
              </span>
            </td>
          </tr>
        </table>
  
      </div>
    </div>


     <!-- This is Parliament chart -->
     <div class="col-md-6" *ngIf="!constituency">
        <div class="card">
          <div id="container"></div>
        </div>
      </div>
    
    <!-- This is pie chart for vote share -->
    <div class="col-md-6">
      <div class="card">
        <div id="partywisepiechart"></div>
      </div>
    </div>
  
  
    <!-- This is Bar chart -->
    <div class="col-md-6" *ngIf="!constituency">
      <div class="card">
        <div id="Barchart"></div>
      </div>
    </div>
  
  
    <!-- This is the chart for Cate wise piechart -->
    <div class="col-md-6" *ngIf="!constituency">
      <div class="card">
        <div id="castewisepiechart"></div>
      </div>
    </div>
  
  
   <!--This is Tree Chart  -->
    <div class="col-md-6" *ngIf="!constituency">
      <div class="card">
        <div id="treechart"></div>
      </div>
    </div>
  
  
    <!-- This is Map chart -->
    <div class="col-md-6" *ngIf="shouldShowMapCard">
      <div class="card">
        <div class="row justify-content-center tabletitle m-t-20 f-s-20">Party Wise Seats Won</div>
        <div class="row justify-content-center">
          <span class="m-t-6">
            {{state}}
            <ng-container *ngIf="state && election"> &#8594; </ng-container>
            {{election}}
            <ng-container *ngIf="election && year"> &#8594; </ng-container>
            {{year}}
            <ng-container *ngIf="year && constituency"> &#8594; </ng-container>
            {{constituency}}
          </span>
        </div>
        <div class="d-flex justify-content-space">
          <div #mapContainer class="m-l-48"></div>
          <div #infoPanel id="infoPanel" class="info-panel m-r-40"></div>
        </div>
      </div>
    </div>
  
    <div class="col-md-6" *ngIf="!constituency">
      <div class="card">
        <div id="bubblechart" style="width: 100%; height: 400px;"></div>
      </div>
    </div>

    <p class="p-20">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>

  
    <!-- This is for details of the participation -->
    <app-participation-details></app-participation-details>
  
  </div>

  <div class="d-flex m-t-30 p-l-64 p-r-64">
    <h3>Disclaimer:</h3> 
    <p class="font-style-italic">The data shown and visualizations presented on this platform are created by the user. The company holds no legal obligation for the content. For reporting misuse or inappropriate data, please contact support at <a href="mailto:support@ivinstrategies.com">support&#64;ivinstrategies.com</a>.</p>
  </div>

  <p class="m-t-30 text-center">Pro-bite by Idovin Strategies Pvt. Ltd.</p>
  
  <div class="popup" *ngIf="showPopup">
    <div class="popup-content">
      <div class="row justify-content-center tabletitle m-t-20">{{selectedState}} Districts Map</div>
        <button (click)="closePopup()" class="float-right">
          <span class="material-icons">close</span>
        </button>
      <svg #districtMap></svg>
    </div>
    <div class="popup-overlay" (click)="closePopup()"></div>
  </div>
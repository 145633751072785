<div class="form-container m-t-24">

      <!-- Profile Form Starts -->
      <form action="" [formGroup]="profileFormGroup" (ngSubmit)="submit()">
        <!-- <mat-dialog-content id="scrollbar"> -->
          <div class="row">
            
            <!-- Profile Picture -->
            <div class="col-12 mb-3 d-flex align-items-center">
              <div class="col-4 col-md-3">
                <mat-label class="mat-subtitle-2 f-w-600 imagetext">
                  Profile Picture <sup class="required-field">*</sup>
                </mat-label>
              </div>
              <div class="col-8 col-md-9">
                <input type="file" accept="image/*" id="profilePicture" name="selectedUserProfile" 
                       (change)="profilePicture($event)" hidden>
                <label for="profilePicture">
                  <img *ngIf="digitalSignImageUrl" class="profileimage" [src]="digitalSignImageUrl">
                  <img *ngIf="!digitalSignImageUrl" mat-card-image [src]="selectedImage" alt="Upload Icon">
                </label>
                <div *ngIf="fileTypeError" class="f-w-600 mandatory">{{ errorMessage }}</div>
              </div>
            </div>
  
            <!-- user Name -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Short Name<sup class="required-field">*</sup></mat-label>
                <input type="Username" matInput formControlName="Username" placeholder="Enter Your User Name" required>
                <mat-error *ngIf="checkError('Username', 'required')">Short Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- First Name -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>First Name<sup class="required-field">*</sup></mat-label>
                <input type="Firstname" matInput formControlName="Firstname" placeholder="Enter Your First Name" required>
                <mat-error *ngIf="checkError('Firstname', 'required')">First Name is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Last Name -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Last Name<sup class="required-field">*</sup></mat-label>
                <input type="lastname" matInput formControlName="lastname" placeholder="Enter Your Last Name" required>
                <mat-error *ngIf="checkError('lastname', 'required')">Last Name is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Date of Birth -->
            <div class="col-12 col-md-6 mb-3">
            <!-- <app-label text="Date Of Birth" for="shortDescription"></app-label> -->
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date of Birth <sup class="required-field">*</sup></mat-label>
                <input matInput [matDatepicker]="picker" formControlName="dob" placeholder="Select DOB" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="checkError('dob', 'required')">Date of Birth is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Email -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email <sup class="required-field">*</sup></mat-label>
                <input type="email" matInput formControlName="email" placeholder="Ex. xyz@ivin.com" required>
                <!-- <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error> -->
                <mat-error *ngIf="checkError('email', 'required')">Email is required</mat-error>
                <mat-error *ngIf="checkError('email', 'email')">Invalid email address</mat-error>
              </mat-form-field>
            </div>
            
            
            
            <!-- Gender -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Gender <sup class="required-field">*</sup></mat-label>
                <mat-select formControlName="gender" required placeholder="Gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('gender', 'required')">Gender is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Phone Number -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Phone Number <sup class="required-field">*</sup></mat-label>
                <span matPrefix>+91&nbsp;</span>
                <input matInput formControlName="phoneNumber" placeholder="Enter your phone number" required>
                <mat-error *ngIf="checkError('phoneNumber', 'required')">Phone Number is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Age -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Age <sup class="required-field">*</sup></mat-label>
                <input matInput formControlName="age" placeholder="Enter your age" required>
                <mat-error *ngIf="checkError('age', 'required')">Age is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Country code -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Country Code <sup class="required-field">*</sup></mat-label>
                <input matInput formControlName="Countrycode" placeholder="Enter your Country Code" required>
                <mat-error *ngIf="checkError('Countrycode', 'required')">Country Code is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Country -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Country<sup class="required-field">*</sup></mat-label>
                <input matInput formControlName="Country" placeholder="Enter your Country" required>
                <mat-error *ngIf="checkError('Country', 'required')">Country is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Address -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Address <sup class="required-field">*</sup></mat-label>
                <textarea matInput formControlName="address" placeholder="Enter your address" required></textarea>
                <mat-error *ngIf="checkError('address', 'required')">Address is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- State -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>State <sup class="required-field">*</sup></mat-label>
                <mat-select formControlName="state" required placeholder="State">
                  <mat-option value="Andhra Pradesh">Andhra Pradesh</mat-option>
                  <mat-option value="Telangana">Telangana</mat-option>
                  <mat-option value="others">Others</mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('state', 'required')">State is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- City -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>City <sup class="required-field">*</sup></mat-label>
                <mat-select formControlName="city" required placeholder="City">
                  <mat-option value="Visakhapatnam">Visakhapatnam</mat-option>
                  <mat-option value="others">Others</mat-option>
                </mat-select>
                <mat-error *ngIf="checkError('city', 'required')">City is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- Pincode -->
            <div class="col-12 col-md-6 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Pincode <sup class="required-field">*</sup></mat-label>
                <input matInput formControlName="pincode" placeholder="Enter your pincode" required>
                <mat-error *ngIf="checkError('age', 'required')">Age is required</mat-error>
              </mat-form-field>
            </div>
            
            <!-- Short Description -->
            <div class="col-12 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Short Description<sup class="required-field">*</sup></mat-label>
                <textarea matInput formControlName="shortdescription" placeholder="Type your Description" required></textarea>
                <mat-error *ngIf="checkError('shortdescription', 'required')">Short Description is required</mat-error>
              </mat-form-field>
            </div>
  
            <!-- About your self -->
            <div class="col-12 mb-3">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>About Your Self<sup class="required-field">*</sup></mat-label>
                <textarea matInput formControlName="aboutyourself" placeholder="Type your YourSelf" required></textarea>
                <mat-error *ngIf="checkError('aboutyourself', 'required')">Your Self is required</mat-error>
              </mat-form-field>
            </div>
            
          </div>
        <!-- </mat-dialog-content> -->
  
        <!-- Action Buttons -->
        <div align="center">
          <button mat-raised-button class="blackbutton" type="submit">Save</button>
          <button mat-button mat-dialog-close class="m-l-20 cancel-border" type="button">Cancel</button>
        </div>
      </form>

</div>    
<div class="main-content">

    <div class="row">
        <section class="example-container m-l-54 m-t-48 mat-elevation-z8" tabindex="0"  id="scrollbar">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              
                <!-- Position Column -->
                <ng-container matColumnDef="position">
                  <th mat-header-cell *matHeaderCellDef class="table-headings">
                     S.No
                  </th>
                  <td mat-cell *matCellDef="let element; let i = index"> 
                    <!-- Add a checkbox for individual rows -->
                    <!-- <mat-checkbox></mat-checkbox> -->
                    {{i + 1}} 
                  </td>
                </ng-container>
              
                <!-- Name Column -->
                <ng-container matColumnDef="startdate">
                  <th mat-header-cell *matHeaderCellDef class="table-headings"> Start Date </th>
                  <td mat-cell *matCellDef="let element">{{ element.EventDate | date: 'dd-MM-yyyy' }}</td>
                </ng-container>
              
              
                <!-- Symbol Column -->
                <ng-container matColumnDef="starttime">
                  <th mat-header-cell *matHeaderCellDef class="table-headings"> Start Time </th>
                  <td mat-cell *matCellDef="let element"> {{element.StartTime}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="endtime">
                    <th mat-header-cell *matHeaderCellDef class="table-headings"> End Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.EndTime}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                <ng-container matColumnDef="text">
                    <th mat-header-cell *matHeaderCellDef class="table-headings"> Text </th>
                    <td mat-cell *matCellDef="let element"> {{element.Text}} </td>
                  </ng-container>

                 <!-- Star Column -->
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="table-headings">Edit</th>
                    <td mat-cell *matCellDef="let element">
                      <button mat-icon-button (click)="rowid(element.id); scheduleeventid(element.EventId)" (click)="schedule(eventscheduledialogbox)">
                        <mat-icon>edit</mat-icon>
                      </button>
                    </td>
                </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
                 <!-- Message to display when there is no data -->
              <div *ngIf="dataSource.data.length === 0" class="text-center m-t-64">
                No Data Available
              </div>
        
              <!-- <div class="sticky-paginator">
                <mat-paginator [pageSizeOptions]="[5, 10]" showFirstLastButtons></mat-paginator>
              </div> -->
        </section>
    </div> 

    <!-- Button -->
    <div class="row justify-content-end m-b-64 m-t-32">
        <div class="col-sm-8">
            <button mat-raised-button class="m-r-12 m-b-12 blackbutton save-cancel-button" (click)="schedule(eventscheduledialogbox)">Add or Update</button>
        </div>
    </div>

</div>     






<!-- popup starts here -->
<ng-container>
    <ng-template #eventscheduledialogbox>

      <div mat-dialog-title class="text-center popupheader-black">Event Schedule Details</div>
        <form action="" [formGroup]="scheduleform" (ngSubmit)="datepost()">
            <mat-dialog-content  id="scrollbar">
                
                <div class="row">
                    <div class="col-md-2 date-details" *ngFor="let date of dates" [ngClass]="{ 'active': isSelected(date.date)}">
                        <a class="date-href" (click)="selectDate(date.day, date.date)">
                            <div class="date-day"> {{ date.day }} </div>
                            <div class="date-numeric"> {{ date.date.split(' ')[0] }} </div>
                            <!-- <div class="date-month"> {{ date.month }} </div> -->
                        </a>
                    </div>
                </div>


                <div>
                    <div class="row">

                        <!-- Additional start times with delete icons -->
                        <div class="col-md-12">
                            <mat-label class="mat-subtitle-2 f-w-400 d-block m-b-16">Start Time<sup class="required-field">*</sup></mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [matTimepicker]="'timepicker'" type="time" placeholder="Select Time" formControlName="eventtime">
                                <mat-icon matSuffix>schedule</mat-icon>
                                <mat-timepicker-toggle matSuffix [for]="'timepicker'"></mat-timepicker-toggle>
                                <mat-timepicker [id]="'timepicker'" [seconds]="true"></mat-timepicker>
                                <mat-error>Time is required</mat-error>
                            </mat-form-field>
                              <!-- Additional text field for added start times -->
                            <ng-container>
                                <mat-form-field class="m-l-10" appearance="outline">
                                    <input matInput placeholder="comments" formControlName="additionalField">
                                </mat-form-field>
                            </ng-container>

                            <!-- <button mat-icon-button color="warn" (click)="removeStartTime(i)" *ngIf="i > 0">
                                <mat-icon>delete</mat-icon>
                            </button> -->
                        </div>

                        <!-- <div class="col-md-12">
                            <a (click)="addStartTime()" class="add-end-date-link">+ Add Schedule Fields</a>
                        </div> -->


                        <div class="col-md-12 m-t-16">
                            <mat-label class="mat-subtitle-2 f-w-400 d-block m-b-16">End Time<sup class="required-field">*</sup></mat-label>
                            <mat-form-field appearance="outline">
                                <input matInput [matTimepicker]="endPicker" type="time" placeholder="Select" formControlName="endEventTime">
                                <mat-icon matSuffix>schedule</mat-icon>
                                <mat-timepicker-toggle matSuffix [for]="endPicker"></mat-timepicker-toggle>
                                <mat-timepicker #endPicker [seconds]="true"></mat-timepicker>
                                <mat-error>End Time is required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </mat-dialog-content>
        <mat-dialog-actions align="center">
            <button mat-button mat-dialog-close class="m-r-20 cancel-border">Cancel</button>
            <button mat-raised-button class="blackbutton">Save Next</button>
        </mat-dialog-actions>
    </form> 
    </ng-template>
  </ng-container>
import { Component } from '@angular/core';

@Component({
  selector: 'app-data-ops',
  templateUrl: './data-ops.component.html',
  styleUrls: ['./data-ops.component.scss']
})
export class DataOpsComponent {

}

import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IvinService } from 'src/app/ivin.service';

// Declare google variable
declare const google: any;

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements AfterViewInit {

  @ViewChild('pieChart', { static: true }) pieChart!: ElementRef;
  defaultOption: any = 'KRISHNAMPALEM-2';
  pollingstationame: any;
  votersdata: any;
  totalvoters: any;
  malevoters: any;
  isLoading: boolean = false;
  femalevoters: any;

  public pie_ChartData = [
    ['Task', 'Hours per Day'],
    ['Work', 11],
    ['Eat', 2],
    ['Commute', 2],
    ['Watch TV', 2],
    ['Sleep', 7]
  ];

  public pie_ChartOptions = {
    // title: 'My Daily Activities',
    // width: 700,
    // height: 500
    is3D:true,
  };

  constructor(private ivinservice:IvinService) {}

  // ngOnInit(): void { 
  //   this.getdatavoters();
    
  // }


  ngAfterViewInit() {
      google.charts.load('current', { packages: ['corechart'] });
      google.charts.setOnLoadCallback(() => {
        this.drawPieChart();
        this.getdatavoters();
      });
    };
  

  drawPieChart() {
    // Update pie_ChartData to include total voters, male voters, female voters
    this.pie_ChartData = [
      ['Task', 'Hours per Day'],
      ['Total Voters', this.totalvoters],
      ['Male Voters', this.malevoters],
      ['Female Voters', this.femalevoters]
    ];

    const chartData = google.visualization.arrayToDataTable(this.pie_ChartData);
    const chart = new google.visualization.PieChart(this.pieChart.nativeElement);
    chart.draw(chartData, this.pie_ChartOptions);
  }


dropdownnames(event:any){
  this.isLoading = true;
  this.pollingstationame = event.value;
  console.log("selectedname",this.pollingstationame);
  this.getdatavoters();
}

getdatavoters(){
  // this.pollingstationame = "KRISHNAMPALEM-2"
  this.ivinservice.pollingdaydowmload(this.defaultOption || this.pollingstationame).subscribe((data:any)=>{
    console.log("data",data)
    this.votersdata = data.Result[0];
    console.log("this.votersdata",this.votersdata);
    this.totalvoters = this.votersdata.Total_Voters;
    this.malevoters = this.votersdata.Total_Male;
    this.femalevoters = this.votersdata.Total_Female;

    this.drawPieChart();
    this.isLoading = false;
  })
}

}

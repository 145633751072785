<div class="main-content">
  <h1 class="page-title">Communication</h1>

  <div class="row">
    <div class="col-md-6 d-flex align-items-center">
      <button mat-raised-button class="blackbutton" (click)="onclickCreate()">
        <mat-icon>create</mat-icon>
        Create Communication
      </button>
    </div>
    <div class="col-md-6">
      <mat-form-field appearance="outline" class="float-right">
        <mat-select required [(ngModel)]="ViewAllStatus" (selectionChange)="ViewAllFilter()">
          <mat-option value="ViewAll">View All</mat-option>
          <mat-option value="SendNow">Sent</mat-option>
          <mat-option value="SaveAsDraft">Draft</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!--Table Starts here-->
  <div class="row">
    <section class="table-container mat-elevation-z8 m-t-13" id="scrollbar">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Sno">
          <th mat-header-cell *matHeaderCellDef class="table-headings">S#</th>
          <td mat-cell *matCellDef="let element; let i = index "> {{ i+1+paginator.pageIndex*paginator.pageSize }} </td>
        </ng-container>
      
        <ng-container matColumnDef="Communicationname">
          <th mat-header-cell *matHeaderCellDef class="table-headings">Communication Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex gap-4" *ngIf="element.CommunicationFor === 'communication' || element.CommunicationFor === 'quickinsights'">
              <span class="material-symbols-outlined">
                {{ element.CommunicationFor === 'communication' ? 'chat' : 'lightbulb' }}
              </span>
              {{ element.CommunicationName }} 
              <mat-icon matTooltip="{{element.Discription}}">info_outline</mat-icon>
            </div>
            <div class="d-flex gap-4" *ngIf="element.CommunicationFor !== 'communication' && element.CommunicationFor !== 'quickinsights'">
              {{ element.CommunicationName }} 
              <mat-icon matTooltip="{{element.Discription}}">info_outline</mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef class="table-headings">Status</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.Status === 'SendNow'" class="completed-color m-l-64">
              Completed
            </div>
            <div *ngIf="element.Status === 'SaveAsDraft'" class="incomplted-color m-l-64">
              Incompleted
            </div>
            {{ element.CreatedOn | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Audienceincluded">
          <th mat-header-cell *matHeaderCellDef class="table-headings">Audience Included</th>
          <td mat-cell *matCellDef="let element"> {{ element.Count }} </td>
        </ng-container>

        <ng-container matColumnDef="option">
          <th mat-header-cell *matHeaderCellDef class="table-headings">Actions</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="$event.stopPropagation();" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="ReloadPage()">
                <mat-icon>refresh</mat-icon>
                <span>Refresh</span>
              </button>
              <button mat-menu-item (click)="EditView(element)">
                <mat-icon>edit</mat-icon>
                <span>Edit & View</span>
              </button>
              <button mat-menu-item (click)="CopyEdit(element)">
                <mat-icon>file_copy</mat-icon>
                <span>Copy and Edit</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-row"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>
      <!-- Message to display when there is no data -->
      <div *ngIf="dataSource.data.length === 0" class="text-center">
        <p class="m-t-54 f-s-16">Your Communication List is Empty</p>
        <br/>
        <p class="f-s-18">
          To add, Click on
          <a class="communicationlink textdecoration-underline pointercursor" (click)="onclickCreate()">Create Communication</a>
        </p>
      </div>
      <div class="sticky-paginator">
        <mat-paginator [pageSizeOptions]="[50, 100, 200]" showFirstLastButtons></mat-paginator>
      </div>
    </section>
  </div>
</div>
<!--Designed and Developed by Krishna Kanth Hanumanthu & Syamala Kolusu-->
<div class="blank-layout-container align-items-center justify-content-center overflowhidden">
    <div>
        <img class="w-5" src="assets/images/logos/ivin-logo.svg" alt="Logo">
    </div>
    <div class="position-relative row w-100 h-100">
      <!--Image div starts here-->
      <div class="col-lg-6 col-xl-8 p-0 bg-gredient bg-light-primary">
          <div>
              <div class="p-24 h-100 align-items-center justify-content-center img-height d-none d-lg-flex">
                  <div *ngFor="let tile of tiles;">
                      <img class="w-100" src="{{tile.imageUrl}}">
                  </div>
              </div>
          </div>
      </div>
      <!--Image div ends here-->
      <!--Form div starts here-->
      <div class="col-lg-6 col-xl-4 p-0">
          <div class="p-b-10 d-flex align-items-start align-items-lg-center justify-content-center h-100">
              <div class="row justify-content-center w-100">
                  <div class="col-lg-9 max-width-form">
                      <!--Mobile icon comes here-->
                      <p class="textcenter">
                          <img *ngIf="mobile" width="50%" class="align-items-center justify-content-center" src="assets/images/logos/ivin-logo.svg">
                      </p>
                      <!--Mobile icon comes here-->
                      <div class="w-100 p-10 border-radius boxshadow-form">
                          <div *ngFor="let tile of tiles;">
                              <div *ngIf="tile.userid && tile.Password">
                                    <div class="text-center align-items-center m-t-10">
                                        <div class="f-w-500 d-grid">
                                        <mat-card-title class="f-s-24 m-b-10">
                                            Welcome to Idovin Strategies!
                                        </mat-card-title>
                                        <mat-card-subtitle class="m-b-20 f-s-12">
                                            Unlocking Insights with Data-Driven Strategies using Pro and Pro-Bite.
                                        </mat-card-subtitle>
                                        </div>
                                    </div>
                                    <div class="tabs d-flex justify-content-center">
                                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedToggle">
                                            <mat-button-toggle value="Login With Email">Login With Email</mat-button-toggle>
                                            <mat-button-toggle value="Login With OTP">Login With OTP</mat-button-toggle>
                                        </mat-button-toggle-group>
                                    </div>
                                    <!-- Login With Password --> <!-- You should google client id here -->
                                    <div *ngIf="selectedToggle === 'Login With Email'">
                                        <!-- <div class="justify-content-center d-flex m-t-20">
                                            <div id="g_id_onload"
                                                data-client_id="380356771982-rhnh8fsp57v3ckrqtqn9qtsvfhvca10j.apps.googleusercontent.com" 
                                                data-context="signin"
                                                data-ux_mode="popup"
                                                data-login_uri="http://localhost:4200"
                                                data-auto_prompt="false">
                                            </div>
                                            
                                            <div class="g_id_signin"
                                                data-type="standard"
                                                data-shape="rectangular"
                                                data-theme="outline"
                                                data-text="signin_with"
                                                data-size="large"
                                                data-logo_alignment="left">
                                            </div>
                                         </div> -->
                                        <!--Login Form Starts here-->
                                        <form action="" class="m-t-20 p-r-20 p-l-20" (ngSubmit)="login()">
                                            <mat-label class="d-block f-w-500">User Name/Email</mat-label>
                                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                                <span class="material-symbols-outlined p-r-4">
                                                    person
                                                </span>
                                                <input matInput type="text" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="emailFormControl.hasError('required')">User Name/Email <strong>required</strong></mat-error>
                                            </mat-form-field>
                                            <mat-label class="d-block f-w-500">Password</mat-label>
                                            <mat-form-field appearance="outline" class="w-100" color="primary">
                                                <span class="material-symbols-outlined p-r-4">
                                                    lock
                                                </span>
                                                <input matInput [type]="hide ? 'text' : 'password'" [formControl]="passwordFormControl" name="password" [errorStateMatcher]="matcher">
                                                <mat-error *ngIf="passwordFormControl.hasError('required')">Password is <strong>required</strong></mat-error>
                                                <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </button>
                                            </mat-form-field>

                                            <!-- Forgot Password Link -->
                                            <div class="d-flex align-items-center m-b-10">
                                                <a class="text-primary f-w-600 text-decoration-none m-l-auto f-s-14 pointercursor" (click)="forgotpassword()">Forgot Password ?</a>
                                              </div>
                                            <button mat-raised-button color="primary" class="w-100 f-s-18 f-w-600 login-button">Login</button>
                                        </form>
                                        <!--Login Form Ends here-->
                                    
                                    <!-- <div class="m-t-20">
                                        <span class="f-s-16 f-w-400 justify-content-center d-flex">If facing any issues? raise ticket:
                                            <a href="#" class="m-l-4" style="text-decoration: none;">Contact Us</a></span>
                                    </div>
                                    <div class="separator m-t-20">
                                        <div class="line"></div>
                                        <span style="color: rgba(88, 94, 108, 1);">OR</span>
                                        <div class="line"></div>
                                    </div> -->
                                    <div class="m-t-20 m-b-10">
                                        <span class="f-s-16 f-w-400 justify-content-center d-flex align-items-center">Don't have an account?
                                            <a class="m-l-4 f-w-700 text-primary pointercursor" (click)="registration()">Sign Up</a>
                                        </span>
                                        <!-- authentication -->
                                         <div class="m-t-10">
                                            <div id="google-signin-button"></div>
                                        </div>  
                                    </div>
                                    </div>
                                    <!-- Login With OTP -->
                                    <div *ngIf="selectedToggle === 'Login With OTP'" class="otp-form">
                                        <form [formGroup]="otpForm" (ngSubmit)="SendOtp()" class="m-t-20 p-r-20 p-l-20">
                                            <mat-label class="d-block f-w-500">User Name/Email</mat-label>
                                            <mat-form-field appearance="outline" class="w-100 m-b-4" color="primary">
                                                <span class="material-symbols-outlined p-r-4">
                                                    person
                                                </span>
                                                <input matInput type="text" formControlName="otpemail">
                                                <mat-error *ngIf="emailFormControl.hasError('required')">User Name/Email <strong>required</strong></mat-error>
                                            </mat-form-field>
                                            <button mat-raised-button color="primary" class="w-100 f-s-16 f-w-500 login-button">Send OTP</button>
                                        </form>
                                        <!-- <div class="m-t-20">
                                            <span class="f-s-16 f-w-400 justify-content-center d-flex">If facing any issues? raise ticket:
                                            <a href="#" class="m-l-4" style="text-decoration: none;">Contact Us</a></span>
                                        </div>
                                        <div class="separator m-t-20">
                                            <div class="line"></div>
                                            <span style="color: rgba(88, 94, 108, 1);">OR</span>
                                            <div class="line"></div>
                                        </div> -->
                                        <div class="m-t-20">
                                            <span class="f-s-16 f-w-400 justify-content-center d-flex align-items-center">Don't have an account?
                                                <a class="m-l-4 f-w-700 text-primary pointercursor" (click)="registration()">Sign Up</a>
                                            </span>
                                        </div>
                                    </div>
                                    <!--Login Ends here-->

                              </div>
                          </div>
                      </div>
                      <!-- Help Section -->
                      <div>
                        <div class="float-right f-s-14 m-r-10 m-t-10">
                            <a class="light-grey-color text-decoration-none" target="_blank" href="https://ivinpro.atlassian.net/jira/servicedesk/projects/IVIN/queues/custom/">Help</a>
                        </div>
                        <div class="float-right f-s-14 m-r-10 m-t-10">
                            <a class="light-grey-color text-decoration-none" target="_blank" href="https://docs.ivinstrategies.com/">Terms</a>
                        </div> 
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <!--Form div ends here-->
  </div>
</div>
<footer>
  Copyright © 2024 | Idovin Strategies Pvt. Ltd. or its affiliates. All rights reserved. Discover ivin Pro & ivin Pro-bite
</footer>

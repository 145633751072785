<!-- Map chart -->
<div class="col-md-6">
    <div class="card">
        <form class="m-t-40 m-l-32">
            <!-- <h4>Filter</h4>
            <mat-form-field appearance="fill">
                <mat-label>Filter By State</mat-label>
                <mat-select name="states">
                    <mat-option value="andhrapradesh">Andhra Pradesh</mat-option>
                    <mat-option value="kerala">Kerala</mat-option>
                    <mat-option value="tamilnadu">Tamil Nadu</mat-option>
                </mat-select>
            </mat-form-field> -->
        </form>
        <div #mapContainer class="map-container m-l-32"></div>
    </div>
</div>

<div class="form-container m-t-24">
  <!-- Profile Form starts here -->
  <form [formGroup]="myForm">
    <div *ngFor="let item of Candiatdata" class="account-info">
      <div class="tabs-container p-30">

        <!-- Row 1: Party Logo and Party Symbol -->
        <div class="row m-b-20">
          <div class="col-6 d-flex flex-column align-items-center">
            <app-label text="Party Logo" for="partylogo"></app-label>
            <div class="image-container">
              <img mat-card-image class="partylogo" [src]="item.PartyLogo || 'https://via.placeholder.com/250x250?text=No+Profile+Image'">
            </div>
            <div *ngIf="partylogoFileName" class="file-name">
              <p>{{ partylogoFileName }}</p> <!-- Display the filename -->
            </div>
          </div>
          <div class="col-6 d-flex flex-column align-items-center">
            <app-label text="Party Symbol" for="partysymbol"></app-label>
            <div class="image-container">
              <img mat-card-image class="partysymbol" [src]="item.PartySymbol || 'https://via.placeholder.com/250x250?text=No+Profile+Image'">
            </div>
            <div *ngIf="partysymbolFileName" class="file-name">
              <p>{{ partysymbolFileName }}</p> <!-- Display the filename -->
            </div>
          </div>
        </div>
                        

        <!-- Row 2: Upload Party Supporting Files and Party Manifesto -->
        <div class="row m-b-20">
          <div class="col-6 d-flex flex-column align-items-center">
            <app-label text="Upload Party Supporting Files" for="uploadPartySupportingFiles"></app-label>
            <div class="image-container m-t-20 m-b-10">
              <ng-container *ngIf="isPdf(item.UploadPartySupportingFiles); else nonPdf">
                <img src="/assets/images/icons/pdf-icon.png" alt="PDF Icon" class="file-icon" />
              </ng-container>
              <ng-template #nonPdf>
                <img mat-card-image class="partysupportdocuments"  [src]="partysupportingFilesImageUrl || 'https://via.placeholder.com/250x250?text=No+Profile+Image'" />
              </ng-template>
              <!-- <p class="file-name">{{ getFileName(item.UploadPartySupportingFiles) }}</p> -->
              <div *ngIf="partysupportingFilesName" class="file-name">
                <p>{{ partysupportingFilesName }}</p> <!-- Display the filename -->
              </div>
            </div>
          </div>
          <div class="col-6 d-flex flex-column align-items-center">
            <app-label text="Party Manifesto" for="partymanifesto"></app-label>
            <div class="image-container">
              <img mat-card-image class="partymanifestodocuments" [src]="partymanifestoImageUrl || 'https://via.placeholder.com/250x250?text=No+Profile+Image'" />
            </div>
            <div *ngIf="partymanifestoFilesName" class="file-name">
              <p>{{ partymanifestoFilesName }}</p> <!-- Display the filename -->
            </div>
          </div>
        </div>          

        <!-- Row 3: Political Affiliation and Slogan -->
        <div class="row m-b-20">
          <div class="col-6">
            <app-label text="Political affiliation" for="politicalAffiliation"></app-label>
            <noneditablefield text="{{partyname || 'N/A'}}"></noneditablefield>
          </div>
          <div class="col-6">
            <app-label text="Slogan" for="slogan"></app-label>
            <noneditablefield text="{{item.Slogan || 'N/A'}}"></noneditablefield>
          </div>
        </div>

        <!-- Row 4: Activists and Achievements -->
        <div class="row m-b-20">
          <div class="col-6">
            <app-label text="Activists" for="activists"></app-label>
            <noneditablefield text="{{item.Activists || 'N/A'}}"></noneditablefield>
          </div>
          <div class="col-6">
            <app-label text="Achievements" for="achievements"></app-label>
            <noneditablefield text="{{item.Achievements || 'N/A'}}"></noneditablefield>
          </div>
        </div>

        <!-- Row 5: Priorities and Video URLs -->
        <div class="row m-b-20">
          <div class="col-6">
            <app-label text="Priorities" for="priorities"></app-label>
            <noneditablefield text="{{item.Priorities || 'N/A'}}"></noneditablefield>
          </div>
          <div class="col-6">
            <app-label text="VideoURLs" for="videourls"></app-label>
            <noneditablefield text="{{item.VideoURLs || 'N/A'}}"></noneditablefield>
          </div>
        </div>

      </div>

      <!-- Accordion for Mobile -->
    </div>

    <!-- Message for no data available -->
    <div *ngIf="!Candiatdata || Candiatdata.length === 0" class="text-center">
      <p>No data available</p>
    </div>

    <!-- Button -->
    <div class="row justify-content-end m-t-32 m-b-64">
      <div class="col-sm-8">
        <button mat-raised-button class="save-cancel-button blackbutton" (click)="outcorrections(candidatureinformationdialogbox)">Add or Update</button>
      </div>
    </div>
  </form>
  <!-- Profile Form ends here -->
</div>

<!-- mat card end here -->

<!-- popup starts here -->
<ng-container>
  <ng-template #candidatureinformationdialogbox>
      <div mat-dialog-title class="text-center popupheader-black">Candidature information</div>
      <form  [formGroup]="myForm" (ngSubmit)="onSubmit()">
          <mat-dialog-content id="scrollbar">
            <!-- Your loading spinner -->
            <div *ngIf="candidatureinformationspinner" class="loading-spinner overlay">
              <img src="assets/images/ivin-loading.gif" alt="Loading">
            </div>
              <div class="row p-64">
                  <div class="col-lg-6">
                      <!-- Party Logo -->
                      <div class="col-md-12 d-flex m-b-24">
                          <div class="col-sm-3 d-flex align-items-center">
                          <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Party Logo<sup class="required-field">*</sup></mat-label> -->
                          <app-label text="Party Logo" for="Party Logo" [required]="true"></app-label>
                          </div>
                          <div class="col-sm-8 m-r-32">
                              <input type="file" accept="image/" id="candidatedigitalsigninput" name="partyLogo" (change)="partylogo($event)" style="display:none;">
                              <label for="candidatedigitalsigninput">
                              <img *ngIf="partyLogoImageUrl" [src]="partyLogoImageUrl" alt="Digital Sign Image">
                              <div *ngIf="!partyLogoImageUrl">
                                  <img mat-card-image class="partylogo" src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
                              </div>
                              </label>
                              <div *ngIf="fileTypeError" class="f-w-600 mandatory fileTypeError">{{ errorMessage }}</div>
                              <div *ngIf="!fileTypeError && partylogoselectedFileName" class="file-info">
                                <span>File Name: {{ partylogoselectedFileName }}</span>
                                <span>File Size: {{ partylogoselectedFileSize }}</span>
                              </div>
                              <mat-icon *ngIf="partyLogoImageUrl" (click)="removepartylogoImage()">close</mat-icon>
                              <div *ngIf="partylogoFileName" class="file-name">
                                <p>{{ partylogoFileName }}</p> <!-- Display the filename -->
                              </div>
                          </div>
                      </div>

                      <!-- Party Symbol -->
                      <div class="col-md-12 d-flex m-b-24">
                          <div class="col-sm-3 d-flex align-items-center">
                          <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Party Symbol</mat-label> -->
                          <app-label text="Party Symbol" for="Party Symbol" [required]="true"></app-label>
                          </div>
                          <div class="col-sm-8 m-r-32">
                              <input type="file" accept="image/" id="imageinput" name="partySymbol" (change)="partysymbol($event)" style="display:none;">
                              <label for="imageinput">
                              <img *ngIf="partySymbolImageUrl" [src]="partySymbolImageUrl" alt="Digital Sign Image" >
                              <div *ngIf="!partySymbolImageUrl">
                                  <img mat-card-image class="partysymbol" src="/assets/images/svgs/upload-image.svg" alt="Upload Icon" />
                              </div>
                              </label>
                              <div *ngIf="fileTypeError" class="f-w-600 mandatory fileTypeError">{{ errorMessage1 }}</div>
                              <div *ngIf="!fileTypeError && partysymbolselectedFileName" class="file-info">
                                <span>File Name: {{ partysymbolselectedFileName }}</span>
                                <span>File Size: {{ partysymbolselectedFileSize }}</span>
                              </div>
                              <mat-icon *ngIf="partySymbolImageUrl" (click)="removepartysymbolImage()">close</mat-icon>
                              <div *ngIf="partysymbolFileName" class="file-name">
                                <p>{{ partysymbolFileName }}</p> <!-- Display the filename -->
                              </div>
                          </div>
                      </div>
          
                      <!-- Upload Party Supporting Files -->
                      <div class="d-flex m-b-24 m-l-16">
                        <div class="col-sm-3 d-flex align-items-center">
                          <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Upload Party Supporting Files</mat-label> -->
                          <app-label text="Upload Party Supporting Files" for="Upload Party Supporting Files" [required]="true"></app-label>
                          </div>
                          <div class="col-sm-8 m-r-32">
                              <input type="file" accept="image/*,.pdf,.doc,.docx,.csv" id="supporting" name="supportingFiles" (change)="supportingfiles($event)" style="display:none;">
                              <label for="supporting">
                              <img *ngIf="partysupportingFilesImageUrl && !toPdf" [src]="partysupportingFilesImageUrl" alt="Digital Sign Image" >
                              <div *ngIf="!partysupportingFilesImageUrl">
                                  <img mat-card-image class="partysupportdocuments" src="/assets/images/svgs/upload-document.svg" alt="Upload Icon" />
                              </div>
                              </label>
                              <mat-icon (click)="removeFile()">close</mat-icon>
                              <div *ngIf="fileTypeError" class="f-w-600 mandatory fileTypeError">{{ imageerrorMessageforsupportingfiles }}</div>
                              <div *ngIf="partysupportingFilesName" class="file-name">
                                <p>{{ partysupportingFilesName }}</p> <!-- Display the filename -->
                              </div>
                              <div *ngIf="isUploading">
                                <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
                                <p>Loading... {{ progress }}%</p>
                              </div>
                          </div>
                      </div>


                      <!-- Party Manifesto -->
                      <div class="d-flex m-b-24 m-l-16">
                        <div class="col-sm-3 d-flex align-items-center">
                          <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Party Manifesto</mat-label> -->
                          <app-label text="Party Manifesto" for="Party Manifesto" [required]="true"></app-label>
                        </div>
                        <div class="col-sm-8 m-r-32">
                          <input type="file" accept="image/*,.pdf,.doc,.docx,.csv" id="manifesto" name="partyManifesto" (change)="partyManifestoFiles($event)" style="display:none;">
                          <label for="manifesto">
                            <img *ngIf="partymanifestoImageUrl && !toPdf" [src]="partymanifestoImageUrl" alt="Digital Sign Image" class="partymanifestodocuments">
                            <div *ngIf="!partymanifestoImageUrl">
                              <img mat-card-image src="/assets/images/svgs/upload-document.svg" alt="Upload Icon" />
                            </div>
                          </label>
                          <mat-icon (click)="removeManifestoFile()">close</mat-icon>
                          <div *ngIf="fileTypeError" class="f-w-600 mandatory fileTypeError">{{ imageerrorMessageforpartymanifesto }}</div>
                          <div *ngIf="partymanifestoFilesName" class="file-name">
                            <p>{{ partymanifestoFilesName }}</p> <!-- Display the filename -->
                          </div>
                          <div *ngIf="isUploadingManifesto">
                            <mat-progress-bar mode="determinate" [value]="progressManifesto"></mat-progress-bar>
                            <p>Loading... {{ progressManifesto }}%</p>
                          </div>
                        </div>
                      </div>

                  </div>

                  
                      <!-- Political affiliation -->
                  <div class="col-lg-6">
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Political affiliation</mat-label> -->
                                  <app-label text="Political Affiliation" for="Political Affiliation" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8">
                                  <mat-form-field appearance="outline" class="w-100 m-t-16 place">
                                      <mat-select required formControlName="partyname" placeholder="Select Your Party Name">
                                          <mat-option *ngFor="let party of partynames" [value]="party.PID">
                                          {{ party.PartyName }}
                                          </mat-option>
                                      </mat-select>
                                  </mat-form-field>
                              </div>
                          </div>

                            <!-- Slogan -->
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Slogan</mat-label> -->
                                  <app-label text="Slogan" for="Slogan" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8">
                                  <mat-form-field appearance="outline" class="w-100 place">
                                      <input matInput  formControlName="slogan"  placeholder="Type Slogan">
                                  </mat-form-field>
                              </div>
                          </div>

                          <!-- Activists -->
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Activists</mat-label> -->
                                  <app-label text="Activists" for="Activists" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8 d-flex align-items-center">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div *ngFor="let item of tasklist; let i=index">
                                              <span>{{ item }}</span>
                                              <mat-icon (click)="removeact(i)">close</mat-icon>
                                          </div>
                                          <div class="d-flex">
                                              <mat-form-field appearance="outline" class="w-100 place">
                                                  <input #task type="text" matInput  formControlName="activists" placeholder="Type Your Activists">
                                              </mat-form-field>
                                              <!-- <mat-icon (click)="AddTask(task.value);task.value = ''" class="m-t-20">add</mat-icon> -->
                                              <!-- <mat-icon (click)="AddTask(myForm.get('activists')?.value)">add</mat-icon> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  
                              <!-- Achievements -->
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Achievements</mat-label> -->
                                  <app-label text="Achievements" for="Achievements" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8 d-flex align-items-center">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div *ngFor="let item of achlist; let i=index">
                                              <span>{{ item }}</span>
                                              <mat-icon (click)="removeach(i)">close</mat-icon>
                                          </div>
                                          <div class="d-flex">
                                              <mat-form-field appearance="outline" class="w-100 place" >
                                                  <input #ach type="text" matInput  formControlName="achievements" placeholder="Type Your Achievements">
                                              </mat-form-field>
                                              <!-- <mat-icon (click)=" addach(ach.value);ach.value=''" class="m-t-22">add</mat-icon> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <!-- Priorities -->
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Priorities</mat-label> -->
                                  <app-label text="Priorities" for="Priorities" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8 d-flex align-items-center">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div *ngFor="let item of prtlist; let i=index">
                                              <span>{{ item }}</span>
                                              <mat-icon (click)="removeprt(i)">close</mat-icon>
                                          </div>
                                          <div class="d-flex">
                                              <mat-form-field appearance="outline" class="w-100 place">
                                                  <input #prt type="text" matInput  formControlName="priorities" placeholder="Type Your Priorities">
                                              </mat-form-field>
                                              <!-- <mat-icon (click)="addprt(prt.value); prt.value = ''" class="m-t-20">add</mat-icon> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  
                      <!-- Video URLs -->
                          <div class="row">
                              <div class="col-sm-3 d-flex align-items-center">
                                  <!-- <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">Video URLs</mat-label> -->
                                  <app-label text="Video URLs" for="Video URLs" [required]="true"></app-label>
                              </div>
                              <div class="col-sm-8 d-flex align-items-center">
                                  <div class="row">
                                      <div class="col-md-12">
                                          <div *ngFor="let item of videolist; let i=index">
                                              <span>{{ item }}</span>
                                              <mat-icon (click)=" removeurl(i)">close</mat-icon>
                                          </div>
                                          <div class="d-flex">
                                              <mat-form-field appearance="outline" class="w-100 place">
                                                  <input #url matInput  formControlName="videourls" placeholder="Type Your Video URL">
                                                  <mat-error *ngIf="videoForm.get('videourls')?.hasError('required')">URL is required</mat-error>
                                                  <mat-error *ngIf="videoForm.get('videourls')?.hasError('pattern')">Invalid URL format</mat-error>
                                              </mat-form-field>
                                              <!-- <mat-icon (click)=" addurl(url.value);url.value=''" class="m-t-20">add</mat-icon> -->
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                  </div>  
              </div>
          </mat-dialog-content> 
                          <!--Button-->
              <mat-dialog-actions align="center">
                  <button mat-raised-button class="blackbutton">Save</button>
                  <button mat-button mat-dialog-close class="m-r-20 cancel-border">Cancel</button>
              </mat-dialog-actions>
      </form>
  </ng-template>
</ng-container>
<div class="pagination-container">
  <!-- Show total probites of the page -->
  <p class="m-l-64 probites-info">Probites: 04</p>

  <ul class="pagination">
    <!-- Disable 'previous' button if on the first page -->
    <li (click)="navigateToPage(1)" class="arrow" [class.disabled]="index === 1">
      <a>&laquo;</a>
    </li>

    <!-- Iterate over pagination.pages to display the page numbers -->
    <li *ngFor="let page of pagination.pages" [class.active]="page === index" (click)="navigateToPage(page)">
      <a>{{ page }}</a>
    </li>

    <!-- Disable 'next' button if on the last page -->
    <li (click)="navigateToPage(maxPages)" class="arrow" [class.disabled]="index === maxPages">
      <a>&raquo;</a>
    </li>
  </ul>

  <!-- Show current page and total pages -->
  <p class="m-r-64 page-info">Page: {{ index }}</p>
</div>
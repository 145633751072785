<!--Designed and Developed by Krishna Kanth Hanumanthu & Syamala Kolusu-->
<div class="blank-layout-container align-items-center justify-content-center overflowhidden">
    <div class="position-relative row w-100 h-100">
      <!--Image div starts here-->
      <div class="col-lg-6 col-xl-8 p-0 bg-gredient bg-light-primary">
        <div>
          <div class="p-24 h-100 align-items-center justify-content-center img-height d-none d-lg-flex">
            <div *ngFor="let tile of tiles;">
              <img src="{{tile.imageUrl}}">
            </div>
          </div>
        </div>
      </div>
      <!--Image div ends here-->
      
      <!--Form div starts here-->
      <div class="col-lg-6 col-xl-4 p-0">
        <div class="p-32 d-flex align-items-start align-items-lg-center justify-content-center h-100">
          <div class="row justify-content-center w-100">
            <div class="col-lg-9 max-width-form">
              <!--Mobile icon comes here-->
              <p class="textcenter">
                <img *ngIf="mobile" width="50%" class="align-items-center justify-content-center" src="assets/images/logos/ivin-logo.svg">
              </p>
              <!--Mobile icon comes here-->
              <div *ngFor="let tile of tiles;">
                <div *ngIf="tile.userid && tile.Password">
                  <h4 class="f-w-700 f-s-24 m-0">Welcome to IVIN Prosss</h4>
                  <!--Login Form Starts here-->
                  <form action="" class="m-t-30" (ngSubmit)="login()">
                    <mat-label class="f-s-14 f-w-600 m-b-12 d-block">User ID<sup class="mandatory">*</sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100" color="primary">
                      <input matInput type="text" [formControl]="emailFormControl"  placeholder="Enter your Userid" [errorStateMatcher]="matcher">
                      <mat-error *ngIf="emailFormControl.hasError('required')">User ID/Email is <strong>required</strong></mat-error>
                    </mat-form-field>
  
                    <mat-label class="f-s-14 f-w-600 m-b-12 d-block">Password<sup class="mandatory">*</sup></mat-label>
                    <mat-form-field appearance="outline" class="w-100" color="primary">
                       <input matInput [type]="hide ? 'text' : 'password'"  [formControl]="passwordFormControl" placeholder="Password" name="password" [errorStateMatcher]="matcher">
                      <mat-error *ngIf="passwordFormControl.hasError('required')">Password is <strong>required</strong></mat-error>
                       <button mat-icon-button matSuffix type="button" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                         <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                       </button>
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="w-100">LOGIN</button>
                 </form>
                 <!--Login Ends here-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Form div ends here-->
    </div>
  </div>
import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IvinService } from 'src/app/ivin.service';

// Declare google variable
declare const google: any;

@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent {

  @ViewChild('barChart', { static: true }) barChart!: ElementRef;

  ngAfterViewInit() {
    google.charts.load('current', { packages: ['bar'] });
    google.charts.setOnLoadCallback(() => {
      this.drawBarChart();
    });
  };


  drawBarChart() {
    const chartData = google.visualization.arrayToDataTable(this.bar_ChartData);
    const chart = new google.visualization.BarChart(this.barChart.nativeElement);
    chart.draw(chartData, this.bar_ChartOptions);  
  }

  // Bar chart

  public bar_ChartData = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000]];


  public bar_ChartOptions = {
    title: 'Population of Largest U.S. Cities',
    chartArea: { width: '50%' },
    hAxis: {
        title: 'Total Population',
        minValue: 0,
        textStyle: {
            bold: true,
            fontSize: 12,
            color: '#4d4d4d'
        },
        titleTextStyle: {
            bold: true,
            fontSize: 18,
            color: '#4d4d4d'
        }
    },
    vAxis: {
        title: 'City',
        textStyle: {
            fontSize: 14,
            bold: true,
            color: '#848484'
        },
        titleTextStyle: {
            fontSize: 14,
            bold: true,
            color: '#848484'
        }
    }
};




}
